  import React, { Component } from "react"
  import Layout from "../components/layout"
  import bg from "../images/backgrounds/detail-bg.png"
  import whitepapers from "../images/timextender/whitepapers.png"
  import Row from "../components/row"
  import SEO from "../components/seo"
  import text from "../data/nl"
  import ReactModal from "react-modal"
  import solutionTimeXtender from "../images/solutions/business-intelligence/timextender.png"
  import FormWhitePaper from "../components/formWhitePaper"
  import linkedInImage from "../images/hands-on-workshop-timextender/tx-linkedIn-afbeelding.jpg"

  class Timextender extends Component {

    constructor(props) {
      super(props)
      this.state = {
        isModalOpen: false,
      }
    }

    handleModalOpen = () => {
      this.setState({ isModalOpen: true })
    }

    handleModalClose = () => {
      this.setState({ isModalOpen: false })
    }

    render() {
      return (

        <Layout bigfoot={false}>
          <SEO title={text.timextender.meta.title} description={text.timextender.meta.description}/>
          <Row size="small" image={bg} classes="pt-200 detail-page" id="DetailPage">
            <div className="column col-12">
              <img src={solutionTimeXtender} style={{ width: "100%", maxWidth: "480px" }} alt="TimeXtender"/>
            </div>

            <div className="main-column">
              <h3 className="t-24 lh-119 bold">
                SLIMMER, SNELLER EN STABIELER: EEN MODERN DATA WAREHOUSE NIEUWE STIJL.
              </h3>
              <p>
                Voor vragen kunt u contact opnemen met&nbsp;
                <a href="mailto:Christian.Pilon@ddcgroup.com">Christian.Pilon@ddcgroup.com</a>.
              </p>
              <h2 className="t-20">Wat is TimeXtender?</h2>
              <p>
                Met TimeXtender kunnen bedrijven een modern data-estate opbouwen door de overstap te maken naar een
                geïntegreerd platform voor databeheer dat tien keer snellere data-inzichten oplevert.
                TimeXtender biedt een samenhangende datastructuur voor on-premises technologie van Microsoft en
                Azure-dataservices.
                Met TimeXtender kunnen data-engineers en -architecten in een grafische gebruikersinterface een data-estate
                definiëren.
                TimeXtender is een referentie architectuur van Microsoft en nauw geïntegreerd met Microsoft SQL server en
                Azure-dataservices.
              </p>

              <h2 className="t-20">Voordelen TimeXtender</h2>

              <p>
                Met TimeXtender kunt u:
              </p>
              <ul>
                <li>Efficiënt verschillende databronnen ontsluiten, transformeer uw data snel en eenvoudig op een visuele
                  manier volgens uw business logica
                </li>
                <li>Data catalogiseren, modelleren, verplaatsen en documenteren voor analyse- en AI-doeleinden</li>
                <li>Data geautomatiseerd naar analyse oplossingen brengen zoals Qlik, Tableau en PowerBI</li>
                <li>Moderne functionaliteiten zoals Data Lineage, Documentatie, Security en meer gebruiken</li>
                <li>Zonder kennis van scripting te hebben een data-estate maken waarin de code automatisch wordt
                  gegenereerd
                  en eenvoudig kan worden aangepast
                </li>
              </ul>
              <p>
                Dit resulteert in de perfecte balans: tijdrovende repetitieve taken worden geautomatiseerd terwijl de code
                snel en eenvoudig op en visuele wijze is aan te passen.
              </p>
              <iframe style={{ maxWidth: "570px", width: "100%", height: "320px", marginTop: "30px", marginBottom: "30px" }}
                      src="https://www.youtube.com/embed/r8ejCOT2voY" frameBorder="0"
                      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen/>

              <h2 className="t-20">Wanneer TimeXtender?</h2>
              <p>Er zijn verschillende redenen om TimeXtender in te zetten.</p>
              <ol>
                <li>Voldoen aan Europese wetgeving zoals AVG & GDPR</li>
                <li>Gemeenschappelijk en eenduidig datawarehouse voor 1 of meerdere BI analyse oplossingen</li>
                <li>Migratie van BI analyse oplossing</li>
                <li>Data kwaliteit verbeteren</li>
                <li>Eenvoudig een volledige documentatie creëren van uw data omgeving</li>
                <li>New datawarehouse creëren</li>
                <li>Moderniseren van een bestaand datawarehouse</li>
                <li>Samenvoegen data omgeving bij bedrijfsfusie of overname</li>
                <li>Migratie van IT systeem zoals ERP of CRM</li>
                <li>Creëren van een data lake voor AI of Machine learning toepassingen</li>
              </ol>

              <h2 className="t-20">TimeXtender</h2>
              <p>
                TimeXtender is een Deens bedrijf dat in 2006 is opgericht en inmiddels is gegroeid tot leider op het
                gebied
                van Data Warehouse Automation.
              </p>
            </div>
            <div className="side-column">
              <img src={linkedInImage} style={{width:"100%", marginTop:"40px"}} alt="Automatiseer uw datawarehouse met TimeXtender"/>
              <a href="https://ddcgroup.com/hands-on-workshop-timextender"
                 className="hide-899 jumbotron-button mb-40 full-width align-center" style={{ textDecoration: "none", marginTop: 0, marginBottom: "80px" }}>
                Meld je hier aan voor de gratis demo
              </a>
              <div>
                <img src={whitepapers} style={{ width: "313px", display: "block", margin: "auto" }} alt="whitepapers"/>
                <ReactModal isOpen={this.state.isModalOpen}
                            onRequestClose={this.handleModalClose}
                            contentLabel="Example Modal In Gatsby">
                  <FormWhitePaper/>
                </ReactModal>
                <button className="hide-899 jumbotron-button mt-40"
                        onClick={this.handleModalOpen}
                        style={{ textDecoration: "none" }}>
                  Download de checklist voor het bouwen van een modern data-estate
                </button>
                <iframe style={{ maxWidth: "450", width: "100%", height: "250px", marginTop: "30px", marginBottom: "30px"  }}
                        src="https://www.youtube.com/embed/wM9NJ6uK208" frameBorder="0"
                        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen/>
              </div>
            </div>
          </Row>
        </Layout>
      )
    }
  }

  export default Timextender
