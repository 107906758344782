import text from "../data/nl"
import React, { Component } from "react"
import axios from "axios"
import { whitepaper } from "../config/url.conf"
import download from "../data/downloads/DDC_Checklist_For_Building_a_Data_Estate_TimeXtender.pdf"
import BarLoader from "react-spinners/BarLoader"

class FormWhitePaper extends Component {

  constructor(props) {
    super(props)
    this.state = {
      name: "",
      emailAddress: "",
      success: null,
      loading: false,
      validations: {
        name: true,
        emailAddress: true,
      },
    }
    this.handleChange = this.handleChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  render() {
    return (
      <div className="contact-form" id="ContactForm">
        <h3 className="uppercase t-24 bold">Download de checklist voor het bouwen van een modern data-estate</h3>
        <div className={this.state.validations.name ? "valid" : "invalid"}>
          <label className="block bold mb-12 mt-20" htmlFor={text.contact.form.name}>
            {text.contact.form.name}
          </label>
          <input className="full-width p-12 borderless"
                 id={text.contact.form.name}
                 name="name"
                 onChange={this.handleChange}
                 value={this.state.name}
                 type="text"/>
        </div>

        <div className={this.state.validations.emailAddress ? "valid" : "invalid"}>
          <label className="block bold mb-12 mt-20"
                 htmlFor={text.contact.form.emailAddress}>
            {text.contact.form.emailAddress} *
          </label>
          <input className="full-width p-12 borderless"
                 id={text.contact.form.emailAddress}
                 name="emailAddress"
                 onChange={this.handleChange}
                 value={this.state.emailAddress}
                 type="emailAddress"/>
        </div>

        <div>
          <div className="mt-20 pt-12 float-left t-13 lh-115 bold">
            * verplichte velden
          </div>
          <button className="button float-right"
                  onClick={this.handleSubmit}>
            {text.contact.form.submit}
          </button>
          <div className="clearFix"/>
        </div>
        <div className={"t-20 bold align-center mt-30 h-64 relative"}>
          <div className={"center-vertically-and-horizontally " + (this.state.loading ? "opacity-100" : "opacity-0")}>
            <BarLoader sizeUnit={"px"} size={100} color={"white"} loading={this.state.loading}/>
          </div>
          <div className={"transition-opacity mt-8 " + (this.state.success ? "opacity-100" : "opacity-0")}>Whitepaper is gedownload</div>
        </div>
      </div>
    )
  }

  handleChange(e) {
    const name = e.target.name
    const value = e.target.value
    this.setState({ [name]: value })
  }

  handleSubmit() {
    console.log('ja hallo');
    let hasErrors = false
    const validations = {
      name: true,
      emailAddress: true,
    }

    if (this.state.emailAddress === "") {
      validations.emailAddress = false
      hasErrors = true
    }
    if (!/\S+@\S+\.\S+/.test(this.state.emailAddress)) {
      validations.emailAddress = false
      hasErrors = true
    }

    if (hasErrors) {
      this.setState(prevState => {
        return {
          ...prevState,
          validations: {
            name: validations.name,
            emailAddress: validations.emailAddress,
          },
        }
      })
    } else {

      this.setState(prevState => {
        return {
          ...prevState,
          loading: true,
        }
      })

      const link = document.createElement('a');
      link.href = '//ddcgroup.com/images/pages/timextender/DDC_Checklist_For_Building_a_Data_Estate_TimeXtender.pdf';
      link.setAttribute('target', '_bank')
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      const bodyFormData = new FormData()
      bodyFormData.set("name", this.state.name)
      bodyFormData.set("emailAddress", this.state.emailAddress)
      bodyFormData.set("subject", "Whitepaper: ChecklistFor Building a Modern Data Estate")

      axios({
        method: "post",
        url: whitepaper,
        data: bodyFormData,
        config: { headers: { "Content-Type": "multipart/form-data" } },
      }).then(() => {
        this.setState(() => {
          return {
            name: "",
            emailAddress: "",
            success: true,
            loading: false,
          }
        })
        console.log(this.state)
      }).catch(err => {
        console.log(err)
      })
    }
  }
}

export default FormWhitePaper
